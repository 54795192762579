<template>
    <GeneralWrapper v-bind="{ classes, styles, visible, text, label, break_line }">
        <input type="email" class="form-control" :name="name" v-model="displayedValue" :required="required" :readonly="readonly" />        
    </GeneralWrapper>
</template>
<script type="module">
import inputMixin from '../mixins/InputMixin.js';
import GeneralWrapper from './GeneralWrapper.vue';
export default {
    name: 'FormEmail',
    mixins: [inputMixin],
    components: {
        GeneralWrapper
    },
    data () {
        return {

        }
    },
    computed: {

    },
    methods: {

    },
    mounted() {
        
    },
    watch: {
        
    }
};
</script>