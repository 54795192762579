<template>
    <GeneralWrapper v-bind="{ classes, styles, visible, text, label, break_line }">
        <input 
            type="number" 
            class="form-control"
            :name="name" 
            v-model="displayedValue" 
            :required="required" 
            :readonly="readonly" 
            :min="min" 
            :max="this_max"
            :step="step" />        
    </GeneralWrapper>
</template>
<script type="module">
import inputMixin from '../mixins/InputMixin.js';
import GeneralWrapper from './GeneralWrapper.vue';
export default{
    name: 'FormNumeric',
    props: ['min', 'max', 'hasDecimals'],
    mixins: [inputMixin],
    components: {
        GeneralWrapper
    },
    data () {
        return {

        }
    },
    methods: {
        
    },
    mounted() {
        
    },
    computed: {
        this_max() {
            return this.min < this.max ? this.max : null;
        },
        step() {
            return this.hasDecimals ? 0.01 : null;
        }
    },
    watch: {

    }
};
</script>