<template>
<div class="w-100 my-4 col-12" :style="{ height: getHeight() }" :styles="styles">
    <h2 class="separator-title">{{ label }}</h2>
    <hr v-if="showLine" />
</div>
</template>
<script type="module">
import inputMixin from '../mixins/InputMixin.js';
export default {
    name: 'FormBreakLine',
    props: [],
    mixins: [inputMixin],
    data () {
        return {
            showLine: true,
        }
    },
    computed: {

    },
    methods: {
        getHeight() {
            return this.showLine === true ? 1 : 0;
        }
    },
    mounted() {
        
    },
    watch: {
        
    }
};
</script>