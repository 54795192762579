<template>
    <GeneralWrapper v-bind="{ classes, styles, visible, text, label, break_line }">
        <textarea class="form-control" :name="name" v-model="displayedValue" :rows="rows" :required="required" :readonly="readonly" :maxlength="input.propsData.contentSize || ''" @keypress="handleKeyPress"></textarea>        
    </GeneralWrapper>
</template>
<script type="module">
import inputMixin from '../mixins/InputMixin.js';
import textMixin from '../mixins/TextMixin.js';
import GeneralWrapper from './GeneralWrapper.vue';
export default{
    name: 'FormLargeText',
    props: ['rows'],
    mixins: [inputMixin, textMixin],
    components: {
        GeneralWrapper
    },
    data () {
        return {

        }
    },
    methods: {
        
    },
    mounted() {
        
    },
    watch: {

    }
};
</script>