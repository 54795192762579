<template>
    <GeneralWrapper v-bind="{ classes, styles, visible, text, label, break_line }">
        <input 
            class="form-control"
            type="date" 
            :name="name" 
            v-model="displayedValue" 
            :required="required" 
            :readonly="readonly" 
            :min="this_min" 
            :max="this_max" />        
    </GeneralWrapper>
</template>
<script type="module">
import inputMixin from '../mixins/InputMixin.js';
import GeneralWrapper from './GeneralWrapper.vue';
export default{
    name: 'FormDate',
    props: ['min', 'max'],
    mixins: [inputMixin],
    components: {
        GeneralWrapper
    },
    data () {
        return {

        }
    },
    methods: {
        
    },
    mounted() {
        
    },
    computed: {
        this_min() {
            return this.min === 'today' ? new Date() : this.min;
        },
        this_max() {
            return this.max === 'today' ? new Date() : this.max;
        },
    },
    watch: {

    }
};
</script>