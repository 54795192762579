import Vue from 'vue'
import App from './App.vue'
import frag from 'vue-frag';

Vue.directive('frag', frag);
Vue.config.productionTip = false
Vue.config.devtools = true;

// const App = {
//     el: '#app',
//     components: {
//         AppForm,
//     },
// };

//new Vue(App);

window.addEventListener('load', () => {
    const rel = document.getElementById('app');
    if (rel && rel !== null && typeof rel !== 'undefined') {
        new Vue({
            render (h) {
                return h(App, { props: { form_key: rel.innerHTML } })
            }
        }).$mount('#app');
    }
});
