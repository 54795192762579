<template>
    <GeneralWrapper :classes="classes" :styles="styles" :visible="visible" :break_line="input.break_line" :text="text" :label="label">
        <input type="text" class="form-control" :name="name" v-model="displayedValue" :required="required" :readonly="readonly" :maxlength="input.propsData.contentSize || ''" @keypress="handleKeyPress" />        
    </GeneralWrapper>
</template>
<script type="module">
import inputMixin from '../mixins/InputMixin.js';
import textMixin from '../mixins/TextMixin.js';
import GeneralWrapper from './GeneralWrapper.vue';
export default{
    name: 'FormText',
    components: {
        GeneralWrapper
    },
    mixins: [inputMixin, textMixin],
    data () {
        return {

        }
    },
    computed: {

    },
    methods: {

    },
    mounted() {
        
    },
    watch: {

    }
};
</script>