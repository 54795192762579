export default {
    props: ['name', 'input', 'label', 'datasource', 'dataType', 'classes', 'styles', 'value', 'visible', 'required', 'readonly', 'hasDecimals', 'text', 'other'],
    data() {
        return {
            displayedOther: "",
            break_line: false,
        }
    },
    mounted() {
        this.displayedOther = this.other;
        this.break_line = this.input.break_line;
    },
    computed: {
        displayedValue: {
            get: function() {
                return this.value;
            },
            set: function(modifiedValue) {
                this.$emit('input', modifiedValue);
                this.$emit('change', {
                    id: this.name,
                    value: modifiedValue
                });
            }
        }
    },
    watch: {
        displayedOther() {
            this.$emit('changeOther', {
                id: this.name,
                input: this.input,
                value: this.displayedOther
            });
        }
    }
};
