export default {
    props: ['contentSize', 'allowedContent'],
    mounted() {
        
    },
    computed: {
        pattern() {
            let pattern = '';
            if (this.input.propsData.allowedContent !== '' && this.input.propsData.allowedContent !== null && this.input.propsData.allowedContent !== 'all') {
                const patterns = {
                    alphanumeric: "^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚüÜ ]+$",
                    letters_only: "^[a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ]+$",
                    numbers_only: "^[0-9]+$",
                };

                pattern = patterns[this.input.propsData.allowedContent];
            }

            return pattern;
        },
    },
    methods: {
        handleKeyPress(e) {
            if (e.charCode !== 0 && this.pattern !== '') {
                const regex = new RegExp(this.pattern);
                const key = String.fromCharCode(!e.charCode ? e.which : e.charCode);
                if (!regex.test(key)) {
                    e.preventDefault();
                    return false;
                }
            }

            return true;
        }
    },
};
