<template>
    <GeneralWrapper v-bind="{ classes, styles, visible, text, label, break_line }">
        <select class="form-control" :name="name" v-model="displayedValue" :required="required" :readonly="readonly">
            <option v-for="data in datasource" :key="data.key" :value="data.key">{{ data.value }}</option>
        </select>
    </GeneralWrapper>
</template>
<script type="module">
import inputMixin from '../mixins/InputMixin.js';
import GeneralWrapper from './GeneralWrapper.vue';
export default {
    name: 'FormSelect',
    mixins: [inputMixin],
    components: {
        GeneralWrapper
    },
    data () {
        return {

        }
    },
    computed: {

    },
    methods: {

    },
    mounted() {
        
    },
    watch: {
        
    }
};
</script>