<template>
<div>
    <input type="hidden" :name="name" v-model="displayedValue" />
</div>
</template>
<script type="module">
import inputMixin from '../mixins/InputMixin.js';
export default{
    name: 'FormText',
    mixins: [inputMixin],
    data () {
        return {

        }
    },
    methods: {
        
    },
    mounted() {
        
    },
    watch: {

    }
};
</script>