<template>
    <transition name="slide-fade">
        <div v-if="isVisibleModal" class="modal-container">
            <div class="modal-background" @click="handleClose"></div>
            <div class="modal-box">
                <div class="modal-title">{{ title }}</div>
                <div class="modal-content">{{ content }}</div>
                <div class="modal-actions"><a class="btn btn-primary" @click="handleClose">Cerrar</a></div>
            </div>
        </div>
    </transition>
</template>
<script type="module">
export default{
    name: 'Modal',
    props: ['isVisibleModal', 'title', 'content'],
    components: {
        
    },
    data () {
        return {

        }
    },
    methods: {
        handleClose() {
            this.$emit('modalClose');
        }
    },
    mounted() {
        
    },
    watch: {

    }
};
</script>