<template>
    <div v-frag>
        <div class="form-radio-list form-group" :class="classes" :styles="styles" v-if="visible">
            <div class="form-label">{{ label }}</div>
            <p v-if="text" v-html="text" class="mb-1"></p>
            <p v-else class="mb-1">&nbsp;</p>
            <slot />
        </div>
        <div class="w-100" style="height: 0" v-if="break_line">&nbsp;</div>        
    </div>
</template>
<script type="module">
export default {
    name: 'GeneralWrapper',
    props: ['text', 'label', 'classes', 'styles', 'visible', 'break_line']
};
</script>