<template>
    <GeneralWrapper :classes="classes" :styles="styles" :visible="visible" :break_line="input.break_line" :text="text" :label="label">
        <input type="file" class="form-control" :name="name" :required="required" :readonly="readonly" @change="pickedFile" />
        <strong class="d-block" v-if="this.input.propsData.max_file_size > 0">Tamaño máximo: {{ this.input.propsData.max_file_size }} MB</strong>
        <strong class="d-block" v-if="this.input.propsData.valid_extensions.length > 0">Tipos permitidos: {{ this.input.propsData.valid_extensions.join(' ') }}</strong>
    </GeneralWrapper>
</template>
<script type="module">
import inputMixin from '../mixins/InputMixin.js';
import GeneralWrapper from './GeneralWrapper.vue';
export default{
    name: 'FormFile',
    components: {
        GeneralWrapper
    },
	mixins: [inputMixin],
    data () {
        return {

        }
    },
    methods: {
        pickedFile(event) {
            const [file] = event.target.files;
            const maxFileSize = this.input.propsData.max_file_size * 1048576; //Conversion from MB to bytes
            if (maxFileSize > 0 && file.size > maxFileSize) {
                alert('El archivo supera el tamaño máximo permitido');
                return;
            }

            const type = file.type.replace('application/', '').replace('image/', '');
            if (this.input.propsData.valid_extensions.length > 0 && !this.input.propsData.valid_extensions.includes(type)) {
                alert('El tipo del archivo seleccionado no está permitido');
                return;
            }
            
            const fileReader = new FileReader();
            fileReader.addEventListener('load', () => this.displayedValue = fileReader.result);
            fileReader.readAsDataURL(file);
        },
    },
    mounted() {
        
    },
    watch: {

    }
};
</script>