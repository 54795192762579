<style>
@import url(./assets/styles.css);
</style>
<template>
    <div class="dynamic-form container">
        <div v-if="!inputs" class="text-center">
            <h2 class="my-4">Este formulario no se encuentra disponible</h2>
        </div>
        <div v-else>
            <h1>{{ title }}</h1>
            <br />
            <div v-if="description">
                <p v-html="description"></p>
                <hr />
            </div>
            <div class="row">
                <component 
                    v-bind:is="option.type" 
                    v-for="option in inputs" 
                    :key="option.id" 
                    v-bind="option.propsData"
                    :input="option"
                    :other="option.other"
                    @change="processEvent"
                    @changeOther="processEventOther"
                    v-model="option.value">                
                </component>
            </div>
            <div class="row" v-if="inputs && inputs.length > 0">
                <div class="col-sm-12 mt-5">
                    <hr />
                    <button class="btn btn-primary" v-on:click.prevent="sendData">Guardar</button>
                </div>
            </div>
            <Modal :content="modalContent" :title="modalTitle" :isVisibleModal="isVisibleModal" @modalClose="handleModalClose" />
        </div>
    </div>
</template>

<script type="module">
import axios from 'axios';
import FormText from './components/FormText.vue';
import FormSelect from './components/FormSelect.vue';
import FormRadioList from './components/FormRadioList.vue';
import FormCheckList from './components/FormCheckList.vue';
import FormEmail from './components/FormEmail.vue';
import FormNumeric from './components/FormNumeric.vue';
import FormDate from './components/FormDate.vue';
import FormDateTime from './components/FormDateTime.vue';
import FormLargeText from './components/FormLargeText.vue';
import FormSeparator from './components/FormSeparator.vue';
import FormHidden from './components/FormHidden.vue';
import FormFile from './components/FormFile.vue';
import Modal from './components/Modal.vue';

//let dataCache = {};

export default{
    name: 'AppForm',
    components: {
        FormText,
        FormSelect,
        FormRadioList,
        FormCheckList,
        FormEmail,
        FormNumeric,
        FormDate,
        FormDateTime,
        FormLargeText,
        FormSeparator,
        FormHidden,
        FormFile,
        Modal,
    },
    props: ['form_key'],
    data() {
        return {
            title: '',
            description: '',
            inputs: [],
            modalTitle: '',
            modalContent: '',
            isVisibleModal: false,
            redirectAfterClose: false,
        };
    },
    mounted() {
        this.fetchFormData();
    },
    methods: {
        //Custom methods
        showModal(title, message) {
            this.modalTitle = title;
            this.modalContent = message;
            this.isVisibleModal = true;
        },
        fetchFormData() {
            axios
                .get(`${process.env.VUE_APP_BACKEND_URL}form/${this.form_key}`)
                .then(response => {
                    this.inputs = response.data.fields;
                    this.title = response.data.name;
                    this.description = response.data.description;
                })
        },
        getData(value, type, option) {
            axios({
                method: 'post',
                url: process.env.VUE_APP_BACKEND_URL  + 'get_data',
                data: {
                    field_id: option.id,
                    filter_value: value
                },
            })    
            .then(response => option.propsData.datasource = response.data);
        },
        validateData(fields) {
            let invalidFieldValues = fields.filter(field => {
                let errorFound = false;
                if (field.propsData.required) {
                    if (field.values instanceof Array) {
                        const validValues = field.values.filter(value => value.value !== '' && value.value !== null && typeof value.value !== 'undefined').length;
                        if (validValues === 0) {
                            errorFound = true;
                        }
                    }
                    else if (field.values instanceof Object) {
                        if (field.value.value === '' || field.value.value === null || typeof field.value.value === 'undefined') {
                            errorFound = true;
                        }
                    }
                    else {
                        if (field.value === '' || field.value === null || typeof field.value === 'undefined') {
                            errorFound = true;
                        }
                    }
                }

                return errorFound;
            });

            if (invalidFieldValues.length > 0) {
                this.showModal("Datos incompletos", "El campo de " + invalidFieldValues[0].propsData.label + " es obligatorio");
                return false;
            }

            const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            const emailFields = fields.filter(input => input.propsData.visible === true && input.type === 'FormEmail' && input.values !== '' && !emailRegex.test(input.value));
            
            if (emailFields.length > 0) {
                this.showModal("Direcciones de correo no válidas", "El campo de " + emailFields[0].propsData.label + " no tiene una dirección de correo valida");
                return false;
            }
            
            const inputs = fields.filter(item => {
                if (item.values instanceof Array || item.values instanceof Object) {
                    return false;
                }

                const conditionals = item.conditionals.filter(conditional => conditional.type === 'validation');
                if (conditionals.length === 0) {
                    return false;
                }

                return conditionals.every(rule => rule.values.every(value => !eval(item.value + ' ' + value)));
            });

            if (inputs.length > 0) {
                const conditionals = inputs[0].conditionals.filter(conditional => conditional.type === 'validation');
                const rules =  conditionals[0].values.join("\n");
                this.showModal('Condiciones no cumplidas', 'Hay campos que no cumplen con las condiciones establecidas: ' + inputs[0].propsData.label + rules);
                return false;
            }
            
            return true
        },
        syncLabelsValuedId(input, value_id) {
            let value = '';
            const values = input.propsData.datasource.filter(ds => ds.key === value_id);
            if (values !== null && values.length > 0) {
                value = values[0].value;
            }
            return {
                value,
                value_id,
            }
        },
        sendData() {
            const filteredFields = this.inputs.filter(input => input.type !== "FormSeparator" && (input.propsData.visible === true || input.type === 'FormHidden'));
            if (this.validateData(filteredFields)) {
                const fields = filteredFields.map(input => {
                    let values = input.value;
                    if (values instanceof Array && input.propsData.datasource !== null && typeof input.propsData.datasource !== 'undefined') {
                        values = values.map(value => {
                            return this.syncLabelsValuedId(input, value);
                        });
                    }
                    else if (input.propsData.datasource !== null && typeof input.propsData.datasource !== 'undefined') {
                        values = this.syncLabelsValuedId(input, values);
                    }

                    return {
                        field_id: input.id,
                        values,
                        other: input.other,
                        type: input.type,
                    }
                });

                axios({
                    method: 'post',
                    url: process.env.VUE_APP_BACKEND_URL + 'register_data',
                    data: {
                        form_key: this.form_key,
                        fields
                    },
                    headers: {
                        'Content-Type': 'text/plain',
                    },
                })    
                .then(response => {
                    this.redirectAfterClose = true;
                    this.showModal('Respuesta', response.data.message);
                    this.fetchFormData();
                });
            }
        },

        //Event handlers
        processDatasourceChange($event) {
            const inputs = this.inputs.filter(item => {
                const conditionals = item.conditionals.filter(conditional => conditional.type === 'datasource' && conditional.reference === $event.id);
                return conditionals.length > 0;
            });
            
            inputs.forEach((option) => {
                const conditionals = option.conditionals.filter(conditional => conditional.type === 'datasource' && conditional.reference === $event.id);
                this.getData($event.value, conditionals[0].datasource, option);
            });
        },
        processVisibilityChange($event) {
            const inputs = this.inputs.filter(item => {
                const conditionals = item.conditionals.filter(conditional => conditional.type === 'visibility' && conditional.reference === $event.id);
                return conditionals.length > 0;
            });
            
            inputs.forEach((option) => {
                const conditionals = option.conditionals.filter(conditional => conditional.type === 'visibility' && conditional.reference === $event.id);
                conditionals.forEach(conditional => {
                    option.propsData.visible = (conditional.values.includes(':any:') && $event.value !== '') || conditional.values.includes($event.value);
                });
            });
        },
        processEvent($event) {
            this.processDatasourceChange($event);
            this.processVisibilityChange($event);
        },
        processEventOther(event) {
            if (event.value !== null && typeof event.value !== 'undefined') {
                event.input.other = event.value;
            }
        },
        handleModalClose() {
            this.modalTitle = '';
            this.message = '';
            this.isVisibleModal = false;
            if (this.redirectAfterClose) {
                window.location.href = '/';
            }
        },
    },
    computed: {
        //datos computados
    },
    watch: {
        //observación de eventos
    }
};
</script>
