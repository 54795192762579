<template>
    <GeneralWrapper v-bind="{ classes, styles, visible, text, label, break_line }">
        <div class="form-check" v-for="data in datasource" :key="data.key">
            <label class="form-check-label">
                <input type="checkbox" class="form-check-input" :name="name" v-model="displayedValue" :value="data.key" :required="required" :readonly="readonly" />
                <span v-if="![':otro:', ':other:'].includes(data.value)">{{ data.value }}</span>
                <span v-else>Otro:</span>
            </label>
            <input type="text" class="ml-2 form-control-sm d-inline" v-if="[':otro:', ':other:'].includes(data.value)" v-model="displayedOther" />
        </div>
    </GeneralWrapper>
</template>
<script type="module">
import inputMixin from '../mixins/InputMixin.js';
import GeneralWrapper from './GeneralWrapper.vue';
export default {
    name: 'FormCheckList',
    mixins: [inputMixin],
    components: {
        GeneralWrapper
    },
    data () {
        return {

        }
    },
    computed: {

    },
    methods: {

    },
    mounted() {
        
    },
    watch: {
        
    }
};
</script>